import { mapActions, mapGetters } from "vuex"
import ThemisDecision from "@/components/shared/decision"

export default {
  name      : "ReporterIntakeFormField",
  components: {
    ThemisDecision
  },
  data() {
    return {
      showRemoveFieldFormDialog: false,
      fieldToBeRemoved         : undefined
    }
  },
  computed: {
    ...mapGetters({
      formTemplates                                   : "formTemplates/formTemplates",
      fieldsV2                                        : "fields/fieldsV2",
      optionLists                                     : "optionLists/optionLists",
      formTemplateConfigurations                      : "formTemplateConfigurations/formTemplateConfigurations",
      isUpdatingMandatory                             : "formTemplateConfigurations/isUpdatingMandatory",
      isMandatoryUpdated                              : "formTemplateConfigurations/isMandatoryUpdated",
      formTemplateConfigurationRemoved                : "formTemplateConfigurations/formTemplateConfigurationRemoved",
      isRemovingFormTemplateConfiguration             : "formTemplateConfigurations/isRemovingFormTemplateConfiguration",
      channels                                        : "channels/channels",
      domainChangeOnSpeakUpIssueCreationRulesForFields: "automations/domainChangeOnSpeakUpIssueCreationRulesForFields"
    }),
    formTemplateId() {
      return +this.$route.params.id
    },
    formTemplate() {
      return this.formTemplates?.find(formTemplate => formTemplate.id === this.formTemplateId)
    },
    optionListsMap() {
      const optionListsMap = new Object()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    fieldsMap() {
      const fieldsMap = new Object()
      for (const field of this.fieldsV2) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    selectedFormTemplateConfiguration() {
      if (this.$route.params.configurationId) {
        return this.formTemplateConfigurations.find(
          formTemplateConfiguration => formTemplateConfiguration.id === +this.$route.params.configurationId
        )
      }
    },
    selectedField() {
      return this.fieldsMap[this.selectedFormTemplateConfiguration?.fieldId]
    },
    optionListOfSelectedField() {
      return this.optionListsMap[this.selectedField?.optionListId]
    },
    channelsMap() {
      const channelsMap = new Object()
      for (const channel of this.channels) {
        channelsMap[channel.id] = channel
      }
      return channelsMap
    },
    rulesForSelectedField() {
      let rulesForSelectedField
      const domainChangeOnSpeakUpIssueCreationRules = this.domainChangeOnSpeakUpIssueCreationRulesForFields[
        this.selectedFormTemplateConfiguration?.fieldId
      ]
      if (domainChangeOnSpeakUpIssueCreationRules) {
        for (const rule of domainChangeOnSpeakUpIssueCreationRules) {
          for (const condition of rule.value.conditions) {
            if (condition.formTemplateId ===  +this.$route.params.id) {
              rulesForSelectedField = domainChangeOnSpeakUpIssueCreationRules
              break
            }
          }
        }
      }
      return rulesForSelectedField
    }
  },
  methods: {
    ...mapActions({
      notify                         : "shared/notify",
      updateFormTemplateConfiguration: "formTemplateConfigurations/updateFormTemplateConfiguration",
      removeFormTemplateConfiguration: "formTemplateConfigurations/removeFormTemplateConfiguration"
    }),
    handleRemoveFormTemplateConfiguration() {
      this.fieldToBeRemoved = this.selectedField
      this.removeFormTemplateConfiguration(this.selectedFormTemplateConfiguration.id)
    },
    getChannelName(channelId) {
      return this.channelsMap[channelId]?.name
    },
    isTheLastRuleOfSelectedField(rule) {
      return rule !== this.rulesForSelectedField[this.rulesForSelectedField.length - 1]
    }
  },
  watch: {
    formTemplateConfigurationRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "915",
            parameters: {
              fieldSystemName : this.fieldToBeRemoved.systemName,
              formTemplateName: this.formTemplate.name
            }
          })
          this.showRemoveFieldFormDialog = false
          this.fieldToBeRemoved          = undefined

          this.$router.push({
            name  : "reporter-intake-form",
            params: {
              id: this.formTemplateId
            }
          })
        }
      }
    },
    isRemovingFormTemplateConfiguration: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_FORM_FIELD.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_FORM_FIELD.pActions[1].buttonProps.loading  = newValue
      }
    }
  }
}